import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import RichContent from '@pretto/bricks/website/about/components/RichContent'

import { ChevronDownBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const MobileContent = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`
export const MobileTabOption = styled.div`
  padding: ${g(3)};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};

  & + & {
    border-top: none;
  }
`
export const MobileTabTitle = styled.div`
  display: flex;
  align-items: center;
  min-height: ${g(3)};
  cursor: pointer;
`
export const TabBullet = styled.div`
  border-radius: ${g(1)};
  height: ${g(1)};
  width: ${g(1)};
  background-color: ${({ theme }) => theme.colors.neutral1};
  margin-right: ${g(2)};
  flex-shrink: 0;
`
export const TabTitle = styled.div`
  ${typo.button18};
  overflow: hidden;
  margin-right: ${g(2)};
`
export const TabArrow = styled(ChevronDownBold)`
  margin-left: auto;
  transform: rotate(0deg);
  transition: transform 400ms ease-in-out;
  flex-shrink: 0;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
    `};
`
export const Result = styled(RichContent)`
  ${typo.bodyBook16};
  margin-left: ${g(3)};
  margin-top: ${g(2)};
`
