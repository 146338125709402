import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import PropTypes from 'prop-types'
import { useState } from 'react'

import * as S from './styles'

const MobileTabOption = ({ allowedTypes, tabContent, tabTitle }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleTabClick = () => {
    setIsOpen(isOpen => !isOpen)
  }

  return (
    <S.MobileTabOption>
      <S.MobileTabTitle onClick={handleTabClick}>
        <S.TabBullet />
        <S.TabTitle>{tabTitle}</S.TabTitle>
        <S.TabArrow $isOpen={isOpen} />
      </S.MobileTabTitle>

      <CollapsibleRow in={isOpen}>
        <S.Result allowedTypes={allowedTypes}>{tabContent}</S.Result>
      </CollapsibleRow>
    </S.MobileTabOption>
  )
}

MobileTabOption.propTypes = {
  allowedTypes: PropTypes.array.isRequired,
  tabContent: PropTypes.string.isRequired,
  tabTitle: PropTypes.string.isRequired,
}

const MobileContent = ({ allowedTypes, tabs }) => (
  <S.MobileContent>
    {tabs.map(({ tabContent, tabTitle }, i) => {
      return <MobileTabOption key={i} tabContent={tabContent} tabTitle={tabTitle} allowedTypes={allowedTypes} />
    })}
  </S.MobileContent>
)

MobileContent.propTypes = {
  allowedTypes: PropTypes.array.isRequired,
  tabs: PropTypes.array.isRequired,
}

export default MobileContent
